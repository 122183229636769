<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.tarif') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="tarif.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="tarif.nama"
                  placeholder="Nama Tarif"
                  v-validate="'required'"
                  data-vv-as="Nama Tarif"
                  name="nama"
                  :class="{'is-invalid': errors.has('nama') }"
                />
                <div
                  v-show="errors.first('nama')"
                  class="invalid-feedback"
                >{{ errors.first('nama') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.description') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="tarif.deskripsi"
                  placeholder="Deskripsi Tarif"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.amount') }}:</label>
              <div class="col-lg-6">
                <cleave class="form-control"
                        :options="configNumber"
                         :class="{'is-invalid': isErrorTarif }"
                        v-model.number="tarif.jumlah"
                        @keyup.native="updateTarif($event)"
                        placeholder="Jumlah Tarif"> </cleave>
                <div
                  v-show="isErrorTarif"
                  class="invalid-feedback"
                >Nominal tarif harus diisi</div>
              </div>
            </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const TarifRepository = RepositoryFactory.get("tarif");
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import Tarif from "../../../model/tarif-model";
import { mapState, mapMutations, mapActions } from 'vuex';

VeeValidate.setMode('passive');
export default {
  components: {

  },
  data() {
    return {
      tarif: new Tarif(),
      isErrorTarif: false,
      configNumber: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralDecimalScale: 2
      }
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  // watch: {
  //   'tarif.jumlah':{
  //     handler(after) {
  //       console.log(after);
  //       this.tarif.jumlah = this.format(after)
  //     },
  //     immediate: true
  //   } 
  // },
  watch: {
    "tarif.jumlah": function (val) {
      if(val || val == 0) {
         this.isErrorTarif = false
      }
    }
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      if (this.dataEdit) {
        this.tarif = Object.assign({}, this.dataEdit);
      }
    },
    submitData: function () {
      
      if(this.tarif.jumlah === null || this.tarif.jumlah === '') {
        this.isErrorTarif = true
        return 
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.tarif.id) {
            this.tarif.jumlah = Number(this.tarif.jumlah)
            this.updateById({ repository: TarifRepository.updateTarif, data: this.tarif });
          } else {
            this.createNew({ repository: TarifRepository.createTarif, data: this.tarif });
          }
          return;
        }
      });
    },
    updateTarif(event){
      event.target.value = event.target.value == "" ? null : event.target.value;
      const value = event.target.value.replace(/\D/g, '');
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
  }
};
</script>
